// Body
$body-bg: #ffffff;
$secondary-bg: #f5f5f5;
$primary: #071733;
$secondary: #3783C1;
$third:#FFFFFF;
$success: #1bcc5a;
$gray-primary: #1d1d1d;
$gray-secondary: #707070;
$menu-trans: #ffffff;
$disabled: #666666;
$danger: #ff0000;
$discount-color: #E75046;
$yellow: #FAD000;
$blue: #3783C0;
$red: #E75046;

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
.text-white{
    color: #ffffff;
}
.bg-success{
    background-color: $success;
}
.bg-secondary{
    background-color: $secondary;
}
.bg-primary{
    background-color: $primary;
}
.text-gray-primary {
    color: $gray-primary;
}
.text-success {
    color: $success;
}
.text-gray-secondary {
    color: $gray-secondary;
}
