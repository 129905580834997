@import '../../variables';
@media screen and (min-width: 768px) {
    #products-container, #related-products-container{
        margin: 1vh 0 0;
        padding: 4vh 0;
        display: block;
        width: 100%;
        background-color: #FFF;
        .product-pagination-container{
            .pagination-arrow,
            .product-pagination-list .product-pagination-list-item{
                font-size: 1.2em;
                margin: 0 10px;
                color: $gray-secondary;
                transition: .3s;
                &.active, &:hover{
                    color: $primary;
                    font-weight: 600;
                    
                }
            }
            .product-pagination-list{
                margin: 0;
                padding: 0;
                list-style: none;
                .product-pagination-list-item{
                    display: inline-block;
                    padding: 0;
                    width: min-content;
                    cursor: pointer;
                    color: $gray-secondary;
                    font-weight: 500;
                    height: min-content;
                    &.active, &:hover{
                        &:after{
                            content: '';
                            display: block;
                            width: 100%;
                            height: 2px;
                            background-color: $primary;
                            margin: 0 auto;
                            position: relative;
                            top: -4px;
                        }
                    }
                    
                }
            }
        }
        #products-filter-container{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 59px;
            width: 83%;
            margin: 3vh auto 6vh auto;
            padding: 2vh 5%;
            #filter-title{
                margin: 0 5% 0 0;
                padding: 0;
                color: $primary;
                font-weight: 500;
                font-size: 1.3em;
            }
            .filter-input{
                margin: 0 1%;
                font-size: 1em;
            }
        }
        #products-list, #related-products-list{
            margin: 2vh auto;
            display: block;
            list-style: none;
            .product-list-item{
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                width: 22vw;
                //height: 35vw;
                margin: 0 auto 3vh auto;
                padding: 10px;
                .product-list-item-image{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    height: 25vw;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                .product-list-item-title{
                    margin: 5px 0 0 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    color: $primary;
                    font-weight: 500;
                    font-size: 0.9rem;
                }
                .product-list-item-categories-container{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    .product-list-item-category{
                        margin: 0 2px 1px 0;
                        padding: 0;
                        font-weight: 300;
                        color: $gray-secondary;
                        display: inline-block;
                        font-size: 0.8em;
                    }
                }
                .product-list-item-sizes-container{
                    margin: 5px 0 0 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    .product-list-item-size{
                        border: 1px solid $secondary;
                        margin: 0 5px 5px 0;
                        padding: 5px 10px;
                        font-weight: 300;
                        cursor: pointer;
                        transition: .3s;
                        &.selected, &:hover{
                            background-color: $secondary !important;
                            color: $third !important;
                        }
                        &.disabled{
                            background-color: transparent;
                            color: $secondary;
                        }
                    }
                }
                .product-list-item-price-container{
                    margin: 0;
                    padding: 4px 0;
                    display: block;
                    width: 100%;
                    span{
                        padding: 0 10% 0 0;
                    }
                    .product-list-item-price-discount{
                        color: $red;
                        font-weight: 400;
                        font-size: 1em;
                        text-decoration: line-through;
                    }
                    .product-list-item-price{
                        color: $gray-secondary;
                        font-weight: 400;
                        font-size: 1.2em;
                    }
                
                }
                .product-list-item-buttons-container{
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    overflow-x: hidden;
                    gap: 5px;
                    .product-list-item-view{
                        background-color: $gray-secondary;
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 28px;
                        cursor: pointer;
                        padding: 0 20px;
                        height: 30px;
                        text-decoration: none;
                        transition: .3s;
                        &:hover{
                            background-color: $primary;
                        }
                        i{
                            color: $third;
                            font-size: 14px;
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .product-list-item-quantity-container{
                        border: 1px solid #707070;
                        border-radius: 28px;
                        padding: 5px 15px;
                        height: 30px;
                        margin: 0;
                        i{
                            color: $gray-secondary;
                        }
                        .product-list-item-input{
                            border: none;
                            width: 100%;
                            //Remove border shadow when focused
                            &:focus{
                                outline: none;
                                box-shadow: none;
                            }
                        }
                    }
                    .product-list-item-shopping-cart{
                        background-color: $primary;
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 28px;
                        cursor: pointer;
                        padding: 0 35px;
                        height: 30px;
                        text-decoration: none;
                        border: none;
                        transition: .3s;
                        &:hover{
                            background-color: $secondary;
                        }
                        i{
                            color: $third;
                            font-size: 14px;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    #products-container, #related-products-container{
        margin: 0 auto;
        padding: 2vh 0;
        display: block;
        width: 90%;
        background-color: #FFF;
        #related-title{
            font-size: 1.3rem;
            text-align: center;
        }
        .product-pagination-container{
            .pagination-arrow,
            .product-pagination-list .product-pagination-list-item{
                font-size: 1em;
                margin: 0 10px;
                color: $gray-secondary;
                transition: .3s;
                &.active, &:hover{
                    color: $primary;
                    font-weight: 600;
                    
                }
            }
            .product-pagination-list{
                margin: 0;
                padding: 0;
                list-style: none;
                .product-pagination-list-item{
                    display: inline-block;
                    padding: 0;
                    width: min-content;
                    cursor: pointer;
                    color: $gray-secondary;
                    font-weight: 500;
                    height: min-content;
                    &.active, &:hover{
                        &:after{
                            content: '';
                            display: block;
                            width: 100%;
                            height: 2px;
                            background-color: $primary;
                            margin: 0 auto;
                            position: relative;
                            top: -4px;
                        }
                    }
                    
                }
            }
        }
        #products-filter-container{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 59px;
            width: 95%;
            margin: 3vh auto 4vh auto;
            padding: 2vh 5%;
            #filter-title{
                margin: 0 3% 0 0;
                padding: 0;
                color: $primary;
                font-weight: 500;
                font-size: 1rem;
            }
            .filter-input{
                margin: 0 1%;
            }
        }
        #products-list, #related-products-list{
            margin: 2vh auto;
            padding: 0;
            list-style: none;
            flex-direction: column;
            
            .product-list-item{
                &:nth-child(n+4){
                    display: none;
                }
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                width: 90%;
                //height: 35vw;
                margin: 0 auto 4vh auto;
                padding: 10px;
                .product-list-item-image{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    height: 90vw;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                .product-list-item-title{
                    margin: 5px 0 0 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    color: $primary;
                    font-weight: 500;
                    font-size: 1em;
                }
                .product-list-item-categories-container{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    .product-list-item-category{
                        margin: 0 2px 1px 0;
                        padding: 0;
                        font-weight: 300;
                        color: $gray-secondary;
                        display: inline-block;
                        font-size: 0.8em;
                    }
                }
                .product-list-item-sizes-container{
                    margin: 5px 0 0 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    .product-list-item-size{
                        border: 1px solid $secondary;
                        margin: 0 5px 0 0;
                        padding: 5px 10px;
                        font-weight: 300;
                        cursor: pointer;
                        transition: .3s;
                        &.selected, &:hover{
                            background-color: $secondary !important;
                            color: $third !important;
                        }
                        &.disabled{
                            background-color: transparent;
                            color: $secondary;
                        }
                    }
                }
                .product-list-item-price-container{
                    margin: 0;
                    padding: 4px 0;
                    display: block;
                    width: 100%;
                    span{
                        padding: 0 10% 0 0;
                    }
                    .product-list-item-price-discount{
                        color: $red;
                        font-weight: 400;
                        font-size: 1em;
                        text-decoration: line-through;
                    }
                    .product-list-item-price{
                        color: $gray-secondary;
                        font-weight: 400;
                        font-size: 1.2em;
                    }
                
                }
                .product-list-item-buttons-container{
                    margin: 0;
                    padding: 5px 0 0 0;
                    width: 100%;
                    overflow-x: hidden;
                    .product-list-item-view{
                        background-color: $gray-secondary;
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 28px;
                        margin-bottom: 0;
                        cursor: pointer;
                        padding: 0 20px;
                        height: 30px;
                        text-decoration: none;
                        transition: .3s;
                        order: 2;
                        &:hover{
                            background-color: $primary;
                        }
                        i{
                            color: $third;
                            font-size: 14px;
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .product-list-item-quantity-container{
                        border: 1px solid #707070;
                        border-radius: 28px;
                        padding: 5px 15px;
                        margin-bottom: 10px;
                        height: 30px;
                        order: 1;
                        //use all width available
                        
                        i{
                            color: $gray-secondary;
                        }
                        .product-list-item-input{
                            border: none;
                            width: 100%;
                            //Remove border shadow when focused
                            &:focus{
                                outline: none;
                                box-shadow: none;
                            }
                        }
                    }
                    .product-list-item-shopping-cart{
                        background-color: $primary;
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 28px;
                        cursor: pointer;
                        padding: 0 35px;
                        height: 30px;
                        text-decoration: none;
                        border: none;
                        transition: .3s;
                        order: 3;
                        &:hover{
                            background-color: $secondary;
                        }
                        i{
                            color: $third;
                            font-size: 14px;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}